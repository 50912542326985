
export default {
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToPost (slug) {
      const url = process.env.BASE_POST
      window.open(`${url}mb/${slug}`, '_blank')
    },
    goToPostAll () {
      const url = process.env.BASE_POST
      window.open(`${url}mb/release`, '_blank')
    }

  }
}
