
export default {
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    first: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
