import { render, staticRenderFns } from "./HomeTopUser.vue?vue&type=template&id=6447b7b0&"
import script from "./HomeTopUser.vue?vue&type=script&lang=js&"
export * from "./HomeTopUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexCarousel: require('/mnt/volume_berlin_01/frontends/mobile-community/components/indexCarousel.vue').default})
