
export default {
  layout: 'default',
  data: () => {
    return {
      language: 'id'
    }
  },
  created () {
    if (process.client) {
      this.checkLang()
    }
  },
  methods: {
    checkLang () {
      const lang = localStorage.getItem('lang')
      if (this.$route.query && this.$route.query.lang) {
        this.selectLanguage(this.$route.query.lang)
      } else if (lang) {
        this.selectLanguage(lang)
      } else {
        this.selectLanguage('id')
      }
    },
    selectLanguage (val) {
      this.$store.commit('public/SET_LANGUAGE', val)
      this.$i18n.setLocaleCookie(val)
      this.$router.push(this.switchLocalePath(val))
      localStorage.setItem('lang', val)
    }
  }
}
